import * as React from 'react'
import { useState } from 'react'
import { Form, Input, InputNumber, Row, Col, message } from 'antd'
import { FormContext } from '../../App'

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 20 },
}

export default function ThirdStep(props) {
  const [specialEquipment, setSpecialEquipment] = useState('')
  const [availableItems, setAvailableItems] = useState('')
  const [extraItems, setExtraItems] = useState('')

  const ctx = React.useContext(FormContext)

  const onFinish = (values) => {
    console.log(values)
  }

  return (
    <Row justify="center">
      <Col xs={24} lg={22} xl={20} xxl={18}>
        <Form
          {...layout}
          layout="vertical"
          form={props.form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ padding: 30 }}
          onValuesChange={async (changedValues, allValues) => {
            const keys = Object.keys(allValues)
            let points = 0
            keys.map((key) => {
              if (key.includes(':')) {
                if (allValues[key]) {
                  const newKey = key.split(':')[0]
                  const value = key.split(':')[1]
                  allValues[newKey] = allValues[key] + ` x ${value}`
                  points += +value * allValues[key]
                }
                delete allValues[key]
              }
              return null
            })
            allValues.inventoryPoints = points
            if (+points <= 100) {
              allValues.job_cost = 'R1,500'
            } else if (+points <= 150) {
              allValues.job_cost = 'R2,000'
            } else if (+points <= 300) {
              allValues.job_cost = 'R2,500'
            } else if (+points <= 400) {
              allValues.job_cost = 'R4,000'
            } else if (+points <= 550) {
              allValues.job_cost = 'R5,000'
            } else if (+points <= 700) {
              allValues.job_cost = 'R10,000'
            } else if (+points <= 1000) {
              allValues.job_cost = 'R15,000'
            } else {
              message.error(
                'Exceeded maximum load, please reduce the number of items',
              )
            }
            ctx.setThirdStep(allValues)
            console.log('allValues', allValues)
          }}
          initialValues={ctx.thirdStep}
        >
          <Form.Item
            name="specialEquipment"
            label="Will you need special equipment EG Forklift/other plant? If so specify"
          >
            <Input
              value={specialEquipment}
              onChange={(e) => setSpecialEquipment(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="extraItems"
            label="Any items you would like us to pay extra special attention to? If so specify"
          >
            <Input.TextArea
              rows={6}
              value={extraItems}
              onChange={(e) => setExtraItems(e.target.value)}
            />
          </Form.Item>
          <div
            style={{
              height: 280,
              border: '1px solid lightgrey',
              borderRadius: 20,
              padding: 20,
              overflowY: 'scroll',
            }}
          >
            <h4>Please indicate the items you have:</h4>
            <br />
            <br />
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="refuse_bag_of_clothing:1"
                  label="bag of clothing: "
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="suitcase:2.5" label="Suitcase:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="medium_size_box:2.5" label="Medium size box:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="large_box:5" label="Large box: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="tv:5" label="TV:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="small_headboard:30" label="Small headboard: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="large_headboard:40" label="Large headboard:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="bunker_bed_single:40"
                  label="Bunker bed (single + single): "
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="bunker_bed_double:45"
                  label="Bunker bed (single + double):"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="three_quater_bed:30" label="3⁄4 bed: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="double_bed:35" label="Double bed:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="queen_sized_bed:40" label="Queen sized bed: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="king_size_bed:45" label="King sized bed:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="sleigh_bed:40" label="Sleigh bed: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 40, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="weave_stand:15" label="Weave stand:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="shoe_stand:20" label="Shoe stand: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="shoe_foot_stool:10" label="Shoe foot stool:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="large_storage_box:40"
                  label="Large storage box: "
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="small_toolbox:10" label="Small toolbox:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="big_toolbox:20" label="Big toolbox: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="bedside_table:5" label="Bedside table:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="wardrobe:35" label="Wardrobe: ">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="tv_stand:10" label="TV stand:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="chest_of_drawers:10" label="Chest of drawers:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="single_door_fridge:20"
                  label="Single door Fridge:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="double_fridge:30" label="Double Fridge:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="patio_set:40" label="Patio set:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="small_bookshelf:20" label="Small bookshelf:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="large_bookshelf:30" label="Large bookshelf:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="single_seater_couch:15"
                  label="Single seater couch:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="double_seater_couch:20"
                  label="Double seater couch:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="three_seater_couch:30" label="3 seater couch:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="five_seater_l_couch:80"
                  label="Five seater L couch:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="seven_seater_l_couch:100"
                  label="7 seater couch:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="bar_chairs:15" label="Bar chairs:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="bar_fridge:15" label="Bar fridge:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="four_dinning_set:50"
                  label="Dinning set (4 seater):"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="six_dinning_set:50"
                  label="Dinning set (6 seater):"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="eight_dinning_set:60"
                  label="Dinning set (4 seater):"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="twelve_dinning_set:100"
                  label="Dinning set (6 seater):"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="patio_set_6_seater:80"
                  label="Patio set 6 seater:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="patio_set_8_seater:100"
                  label="Dinning set (8 seater):"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="plastic_container:10"
                  label="plastic container:"
                >
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="blanket:2.5" label="Blanket:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="books:2.5" label="Books:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col sm={10} lg={10} xl={10} xxl={10}>
                <Form.Item name="home_theater:15" label="Home theater:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={10} xl={10} xxl={10}>
                <Form.Item name="heater_fan:10" label="Heater/fan:">
                  <InputNumber
                    min={0}
                    style={{ marginLeft: 20, width: 100 }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Col>
    </Row>
  )
}
