import * as React from 'react'
import { Form, Row, Col, Select, Radio, InputNumber, Input, Tooltip } from 'antd'
import { FormContext } from '../../App'
import Autocomplete from 'react-google-autocomplete'
// import Map from '../UI/Maps'

const { Option } = Select

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 20 },
}

// const GOOGLE_MAPS_API_KEY = 'AIzaSyAPauGIUstu-hi4LT08OYGOzDpoYMKjANg'
// const GOOGLE_MAPS_API_KEY = 'AIzaSyAVabO2d5ZKGsxonzdWLp2eOew9w3oBGLE'
// const GOOGLE_MAPS_API_KEY = 'AIzaSyCY86Sv7_h8AVze6KIoU8yT4mc8zihdpbw' // sm
const GOOGLE_MAPS_API_KEY = 'AIzaSyBIigLP0RVLi-hOBGpb2TpPEsiFLoljTWE' // sm

const specialBoxesList = ['None', 'Hanger box', 'TV Box', 'Other']

const stairs_list = ['ground floor', '1st floor', '2nd floor', '3rd floor', ' above 3rd floor']

const moving_date = [
  'Between 25th and 27th',
  'Between 3rd and 25th',
  'Between 27th and 3rd',
]

const optionsList = ['yes', 'no']

export default function LastStep(props) {
  const ctx = React.useContext(FormContext)
  const [pickupAddress, setPickupAddress] = React.useState(undefined)
  const [dropOffAddress, setDropOffAddress] = React.useState(undefined)
  const [packagingElements, setPackagingElements] = React.useState("no")
  const [specialBoxes, setSpecialBoxes] = React.useState("None")

  const onFinish = (values) => {
    console.log(values)
  }

  return (
    <Row justify="center">
      <Col xs={24} lg={22} xl={20} xxl={18}>
        <div className="form_control" style={{ width: '100%', marginTop: 50 }}>
          <Autocomplete
            placeholder="Pick-up Address or Suburb/area"
            style={{ marginTop: 0 }}
            apiKey={GOOGLE_MAPS_API_KEY}
            label="Pickup Address"
            className="form_item"
            inputAutocompleteValue={pickupAddress}
            onPlaceSelected={async (place) => {
              console.log(place)
              const lat = place.geometry.location.lat()
              const lng = place.geometry.location.lng()
              console.log(`setPickupAddress -> ${lat}, ${lng}`)

              let obj = {
                formattedAddress: place.formatted_address,
                location: `${lat}, ${lng}`,
              }

              localStorage.setItem("pickupAddress", JSON.stringify(obj));

              ctx.setLastStep((prev) => ({
                ...prev,
                pickupAddress: obj,
              }))

              setPickupAddress({
                formattedAddress: place.formatted_address,
                location: { lat, lng },
              })
            }}
            options={{
              types: ['geocode'],
              componentRestrictions: { country: 'za' },
            }}
          />
        </div>
        <div className="form_control" style={{ width: '100%' }}>
          <Autocomplete
            placeholder="Drop-off address or Suburb/area"
            style={{ marginTop: 0 }}
            apiKey={GOOGLE_MAPS_API_KEY}
            label="Drop-off Address"
            className="form_item"
            inputAutocompleteValue={dropOffAddress}
            onPlaceSelected={async (place) => {
              const lat = place.geometry.location.lat()
              const lng = place.geometry.location.lng()
              console.log(`setDropOffAddress -> ${lat}, ${lng}`)
              let obj = {
                formattedAddress: place.formatted_address,
                location: `${lat}, ${lng}`,
              }
              localStorage.setItem("dropOffAddress", JSON.stringify(obj));

              ctx.setLastStep((prev) => ({
                ...prev,
                dropOffAddress: obj,
              }))

              setDropOffAddress({
                formattedAddress: place.formatted_address,
                location: { lat, lng },
              })

            }}
            options={{
              types: ['geocode'],
              componentRestrictions: { country: 'za' },
            }}
          />
        </div>
        {/* <Map position={dropOffAddress ? dropOffAddress.location : undefined} />
        <br /> */}
        <Form
          {...layout}
          layout="vertical"
          form={props.form}
          name="control-hooks"
          onFinish={onFinish}
          style={{ padding: 30 }}
          onValuesChange={async (changedValues, allValues) => {
            ctx.setLastStep(allValues)
            console.log('allValues', allValues)
          }}
          initialValues={ctx.lastStep}
        >
          {/* <Form.Item
            name="pickupAddress"
            label="Pick-up Address or Suburb/area:"
            rules={[{ required: true, message: "Pick-up address is required" }]}
          >
            <Search
              placeholder="Enter your pickup location"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
            />
          </Form.Item>
          <Form.Item
            name="dropOffAddress"
            label="Drop-off address or Suburb/area:"
            rules={[{ required: true, message: "Drop-off address is required" }]}
          >
            <Search
              placeholder="Enter your drop off location"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
            />
          </Form.Item> */}
          <Form.Item
            name="pickupStairs"
            label="What floor are you moving from?"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="What floor are you moving from?"
              allowClear
            >
              {stairs_list.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="dropoffStairs"
            label="What floor are you moving to?"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="What floor are you moving to?"
              allowClear
            >
              {stairs_list.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="movingDate"
            label="When do you want to move?"
            rules={[{ required: true }]}
          >
            <Select placeholder="When do you want to move?" allowClear>
              {moving_date.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Tooltip title="IE Dismantling, packaging, wrapping of goods">
          <Form.Item
            name="packing"
            label="Do you require pre-removal support?"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {optionsList.map((option) => (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          </Tooltip>
          <Tooltip title="Reassembling unwrapping, cleaning and sorting of goods">
          <Form.Item
            name="unpacking"
            label="Do you require post-removal support?"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {optionsList.map((option) => (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          </Tooltip>
          <Tooltip title="IE 50m bubble wrap, 200m shrink-wrap, 10 extra large boxes">
          <Form.Item
            name="packaging_elements"
            label="Do you require us to supply the packaging elements required for the above mentioned services?"
            rules={[{ required: true }]}
          >
            <Radio.Group value={packagingElements} onChange={(e) => setPackagingElements(e.target.value)}>
              {optionsList.map((option) => (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          </Tooltip>

          {packagingElements === "yes" && <Form.Item name="number_of_boxes" label="How many boxes do you think you will need roughly?: " rules={[{ required: true }]}>
            <InputNumber
              min={0}
              placeholder="0"
            />
          </Form.Item>}
          {packagingElements === "yes" && <Form.Item
            name="special_boxes"
            label="Any special boxes?"
            rules={[{ required: true }]}
          >
            <Select value={specialBoxes} onChange={(e) => setSpecialBoxes(e)} placeholder="Enter any special boxes?" allowClear>
              {specialBoxesList.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>}
          {specialBoxes === "Other" && <Form.Item
            name="otherSpecialBoxes"
            label="Enter name of the special box"
          >
            <Input />
          </Form.Item>}
          <Form.Item
            name="emailQuote"
            label="Would you like us to email you a full quotation?"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {optionsList.map((option) => (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
