import { createContext, useState } from 'react'
import Layout from './components/layout/Layout'
import 'antd/dist/reset.css'
import Wizard from './components/wizard/Wizard'
import styled from 'styled-components'
// import Inventory from './components/UI/Inventory'
// import CustomTable from './components/UI/CustomTable'

export const FormContext = createContext({
  firstStep: {},
  secondStep: {},
  thirdStep: {},
  lastStep: {},

  setFirstStep: (val) => { },
  setSecondStep: (val) => { },
  setThirdStep: (val) => { },
  setLastStep: (val) => { },
})

function App() {
  const [firstStep, setFirstStep] = useState({ isValid: true })
  const [secondStep, setSecondStep] = useState({ isValid: true })
  const [thirdStep, setThirdStep] = useState({ isValid: true })
  const [lastStep, setLastStep] = useState({ isValid: true })

  return (
    <FormContext.Provider
      value={{
        firstStep,
        secondStep,
        thirdStep,
        lastStep,

        setFirstStep,
        setSecondStep,
        setThirdStep,
        setLastStep,
      }}
    >
      <AppWrapper>
        <Layout>
          {/* <Inventory /> */}
          <Wizard />
        </Layout>
      </AppWrapper>
    </FormContext.Provider>
  )
}

const AppWrapper = styled.div`
  text-align: center;
`

export default App
